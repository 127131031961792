<template>
  <component :is="content" :options="combinedData" :modeForm="modeForm" />
</template>

<script>
export default {
  data() {
    return {
      childData: {}
    }
  },
  props: {
    content: {
      type: Object,
      required: false
    },
    options: {
      required: false
    },
    modeForm: {
      required: false
    }
  },
  computed: {
    combinedData() {
      return { ...this.options, ...this.childData }
    }
  }
}
</script>

<style></style>
