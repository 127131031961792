var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.content, {
    tag: "component",
    attrs: {
      "options": _vm.combinedData,
      "modeForm": _vm.modeForm
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }